import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TileService } from 'src/app/services/tile.service';
import { Tile } from 'src/app/interfaces/tile';
import { SkillService } from 'src/app/services/skill.service';
const data = require('../../../assets/data.json');

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  public currentYear: string;
  public navItems: any[] = [
    {
      active: true,
      label: "my tools",
      anchor: "skills",
      position: null
    },
    {
      active: false,
      label: "about me",
      anchor: "about",
      position: null
    },
    {
      active: false,
      label: "projects",
      anchor: "projects",
      position: null
    },
    {
      active: false,
      label: "get in touch",
      anchor: "contact",
      position: null
    }
  ]

  constructor(
    private router: Router,
    private tileService: TileService,
    private skillService: SkillService
  ) { }

  get tiles(): Tile[] {
    return this.tileService.tiles;
  }

  get skils(): any[] {
    return this.skillService.skils;
  }

  get data() {
    return data;
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear().toString();
  }

  public dataSectionNameByIndex(index) {
    return Object.keys(data.sections)[index];
  }

  public openTile(index: number): void {
    let tile = this.tiles[index];
    if (tile.url.indexOf("https:") > -1 && tile.title != "Music Player") {
      this.router.navigate(['/viewer', index]);
    }
    else {
      window.open(tile.url, '_blank');
    }
  }

}
