import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TileService } from 'src/app/services/tile.service';
import { Tile } from 'src/app/interfaces/tile';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-project-viewer',
  templateUrl: './project-viewer.component.html',
  styleUrls: ['./project-viewer.component.scss']
})
export class ProjectViewerComponent implements OnInit {
  tile: Tile;
  backupPosition: number;

  constructor(
    private route: ActivatedRoute,
    private tileService: TileService,
    public sanitizer: DomSanitizer
  ) { }

  get tiles(): Tile[] {
    return this.tileService.tiles;
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.tile = this.tiles[id];
  }

  back() {
    window.scrollTo(0, this.backupPosition);
  }

}
