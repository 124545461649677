import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectViewerComponent } from './components/project-viewer/project-viewer.component';
import { IndexComponent } from './components/index/index.component';
import { TileService } from './services/tile.service';
import { SkillService } from './services/skill.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    ProjectViewerComponent,
    IndexComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
    SkillService,
    TileService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
