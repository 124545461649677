import { Injectable } from '@angular/core';
const data = require('../../assets/data.json');

@Injectable({
  providedIn: 'root'
})
export class SkillService {
  public skils: any[];

  constructor() {
    this.skils = data.sections[this.dataSectionNameByIndex(0)];
  }

  public dataSectionNameByIndex(index) {
    return Object.keys(data.sections)[index];
  }
}
