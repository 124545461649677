import { Injectable } from '@angular/core';
import { Tile } from '../interfaces/tile';
const data = require('../../assets/data.json');

@Injectable({
  providedIn: 'root'
})
export class TileService {
  public tiles: Tile[];

  constructor() {
    this.tiles = data.sections[this.dataSectionNameByIndex(2)];
  }

  public dataSectionNameByIndex(index) {
    return Object.keys(data.sections)[index];
  }
}
